/* eslint-disable */
// Cover all standardized ES6 APIs.
import 'core-js/es6'

// Standard now
import 'core-js/fn/array/includes'
import 'core-js/fn/array/flat-map'
import 'core-js/fn/string/pad-start'
import 'core-js/fn/string/pad-end'
import 'core-js/fn/string/trim-start'
import 'core-js/fn/string/trim-end'
import 'core-js/fn/symbol/async-iterator'
import 'core-js/fn/object/get-own-property-descriptors'
import 'core-js/fn/object/values'
import 'core-js/fn/object/entries'
import 'core-js/fn/promise/finally'

// Ensure that we polyfill ES6 compat for anything web-related, if it exists.
import 'core-js/web'

import 'regenerator-runtime/runtime'

if (typeof window !== 'undefined') {
  if (window.NodeList && !window.NodeList.prototype.forEach) {
    window.NodeList.prototype.forEach = function forEach(callback, thisArg) {
      const arg = thisArg || window
      for (let i = 0; i < this.length; i++) {
        callback.call(arg, this[i], i, this)
      }
    }
  }

  ;(function(arr) {
    arr.forEach(function(item) {
      if (item.hasOwnProperty('prepend')) {
        return
      }
      Object.defineProperty(item, 'prepend', {
        configurable: true,
        enumerable: true,
        writable: true,
        value: function prepend() {
          const argArr = Array.prototype.slice.call(arguments)
          const docFrag = document.createDocumentFragment()

          argArr.forEach(function(argItem) {
            const isNode = argItem instanceof Node
            docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)))
          })

          this.insertBefore(docFrag, this.firstChild)
        },
      })
    })
  })([Element.prototype, Document.prototype, DocumentFragment.prototype])
}
