import { createUseStyles } from 'react-jss'

import { colors } from '../../theme'


export default createUseStyles({
  container: {
    '&, & .ScrollbarsCustom-Wrapper, & .ScrollbarsCustom-Scroller, & .ScrollbarsCustom-Content': {
      height: '100%',
      width: '100%',
    },
    '& .ScrollbarsCustom-Content': {
      display: 'flex',
      flexFlow: 'column',
    },
    '&.is-secure-y-scroll .ScrollbarsCustom-Content > *': {
      minHeight: 'calc(100% + 2rem)',
    },
    '& .ScrollbarsCustom-Track': {
      position: 'absolute',
      background: '#8036FF',
      boxShadow: 'inset 0 0 1px rgba(0, 0, 0, 0.75)',
    },
    '& .ScrollbarsCustom-TrackY': {
      bottom: 0,
      top: 0,
      right: 0,
      width: '1.8rem',
      paddingTop: '1.8rem',
      paddingBottom: '1.8rem',
    },
    '& .ScrollbarsCustom-TrackX': {
      bottom: 0,
      left: 0,
      right: 0,
      height: '1.8rem',
      paddingRight: '1.8rem',
      paddingLeft: '1.8rem',
    },
    '& .ScrollbarsCustom-Thumb': {
      background: '#807BFF',
      border: `2px outset ${colors.grey93}`,
    },
    '& .ScrollbarsCustom-ThumbX': {
      height: '100%',
    },
    '& .ScrollbarsCustom-ThumbY': {
      width: '100%',
    },
    '& .ScrollbarsCustom-Track::before, & .ScrollbarsCustom-Track::after': {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: `2px outset ${colors.grey93}`,
      textAlign: 'center',
      fontSize: '0.8rem',
      color: '#FFFB00',
      backgroundColor: '#807BFF',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    '& .ScrollbarsCustom-TrackX::before, & .ScrollbarsCustom-TrackX::after': {
      content: '""',
      top: 0,
      bottom: 0,
      fontSize: '0.8rem',
      width: '1.8rem',
      backgroundSize: 'auto 0.9rem',
    },
    '& .ScrollbarsCustom-TrackX::before': {
      backgroundImage: 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA0IDkiPgogIDxwYXRoIGZpbGw9IiNGRkZCMDAiIGQ9Ik0uMDAwMDAxOTUgNC40NjQzIDQgLjUwMDE4M1Y4LjQzMDE4TC4wMDAwMDE5NSA0LjQ2NDNaIi8+Cjwvc3ZnPgo=")',
      left: 0,
    },
    '& .ScrollbarsCustom-TrackX::after': {
      backgroundImage: 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA0IDkiPgogIDxwYXRoIGZpbGw9IiNGRkZCMDAiIGQ9Ik00IDQuNDY2MjUtMy41ZS03IDguNDMwMzYgMCAuNTAwMzY2IDQgNC40NjYyNVoiLz4KPC9zdmc+Cg==")',
      right: 0,
    },
    '& .ScrollbarsCustom-TrackY::before, & .ScrollbarsCustom-TrackY::after': {
      content: '""',
      left: 0,
      right: 0,
      height: '1.8rem',
      backgroundSize: '0.9rem auto',
    },
    '& .ScrollbarsCustom-TrackY::before': {
      backgroundImage: 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA5IDQiPgogIDxwYXRoIGZpbGw9IiNGRkZCMDAiIGQ9Im00LjUzNTc5IDAgMy45NjQxMiA0SC41Njk5MTZMNC41MzU3OSAwWiIvPgo8L3N2Zz4K")',
      top: 0,
    },
    '& .ScrollbarsCustom-TrackY::after': {
      backgroundImage: 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA5IDQiPgogIDxwYXRoIGZpbGw9IiNGRkZCMDAiIGQ9Ik00LjUzNDAzIDQgLjU2OTkxNi0yLjVlLTcgOC40OTk5MSAwIDQuNTM0MDMgNFoiLz4KPC9zdmc+Cg==")',
      bottom: 0,
    },
  },
})
