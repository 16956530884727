import { createUseStyles } from 'react-jss'

// import { colors, desktop, fonts} from '../../theme'
import { desktop } from '../../theme'


export default createUseStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
    '& > *:not(:first-child)': {
      marginTop: '1rem',
    },
    '&.is-reverse': {
      flexFlow: 'column-reverse',
    },
    '&.is-reverse > *:not(:first-child)': {
      marginTop: '0',
    },
    '&.is-reverse > *:not(:last-child)': {
      marginTop: '1rem',
    },
  },
  ...desktop({
    container: {
      '& > .is-media__image': {
        maxWidth: '47rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '2rem',
        marginBottom: '4rem',
      },
    },
  }),
})
