import { createUseStyles } from 'react-jss'

import { colors, desktop } from '../../theme'


export default createUseStyles({
  container: {
    padding: '2rem 2.5rem',
    fontSize: '1.8rem',
    lineHeight: '1.2',
    '& a': {
      color: colors.blueLink,
      textDecoration: 'underline',
    },
    '& p:not(:first-child)': {
      marginTop: '2rem',
    },
    '& > p': {
      textIndent: '4rem',
    },
    '& li p': {
      display: 'inline',
    },
  },
  ...desktop({
    container: {
      paddingLeft: '6rem',
      paddingRight: '6rem',
      '& > p': {
        textIndent: '4rem',
      },
    },
  }),
})
