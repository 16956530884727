import { createUseStyles } from 'react-jss'

// import { colors, desktop, fonts} from '../../theme'
import { desktop } from '../../theme'


export default createUseStyles({
  container: {
    '&:not(:first-child)': {
      marginTop: '1rem',
    },
    '&:not(:last-child)': {
      marginBottom: '2rem',
    },
  },
  video: {
    '& video': {
      display: 'block',
      width: '100%',
    },
  },
  ...desktop({}),
})
