import { createUseStyles } from 'react-jss'

import { transition } from '../../theme'


export default createUseStyles({
  hasClick: {
    cursor: 'pointer',
  },
  icon: {
    '-webkit-tap-highlight-color': 'transparent',
    userSelect: 'none',
    display: 'inline-flex',
    position: 'relative',
    fill: (props) => props.color,
    color: (props) => props.color,
    '& svg': {
      flex: '0 0 auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    '& *[fill]:not([fill="none"]):not(.no-fill)': {
      fill: (props) => props.color,
    },
    '& *[stroke]:not([stroke="none"]):not(.no-stroke)': {
      stroke: (props) => props.color,
    },
    '& *[fill]:not([fill="none"]):not(.no-fill), & *[stroke]:not([stroke="none"]):not(.no-stroke)': {
      ...transition(['fill', 'stroke']),
    },
  },
})
