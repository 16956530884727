/* eslint-disable */

(function() {
  function m() {
    function u() {
      return null
    } function l(a) {
      return a ? typeof a === 'object' || typeof a === 'function' : !1
    } function n(a) {
      if (a !== null && !l(a)) {
        throw new TypeError(`Object prototype may only be an Object or null: ${a}`)
      }
    } function v(a, c, A) {
      function k() {} if (!l(a) || !l(c)) {
        throw new TypeError('Cannot create proxy with a non-object as target or handler')
      } let g = c

      c = { get: null, set: null, apply: null, construct: null }; for (var h in g) {
        if (!(h in c)) {
          throw new TypeError(`Proxy polyfill does not support trap '${h}'`)
        } c[h] = g[h]
      } typeof g === 'function' && (c.apply = g.apply.bind(g)); g = B(a); let p = !1

      let q = !1

      if (typeof a === 'function') {
        var e = function() {
          const b = this && this.constructor === e

          const d = Array.prototype.slice.call(arguments)

          k(b ? 'construct' : 'apply'); return b && c.construct ? c.construct.call(this, a, d) : !b && c.apply ? c.apply(a, this, d) : b ? (d.unshift(a), new (a.bind.apply(a, d))()) : a.apply(this, d)
        }

        p = !0
      } else {
        a instanceof Array ? (e = [], q = !0) : e = w || g !== null ? C(g) : {}
      } const x = c.get ? function(b) {
        k('get'); return c.get(this, b, e)
      } : function(b) {
        k('get'); return this[b]
      }

      const D = c.set ? function(b, d) {
        k('set'); c.set(this, b, d, e)
      } : function(b, d) {
        k('set'); this[b] = d
      }

      const y = {}

      f.getOwnPropertyNames(a).forEach(function(b) {
        if (!((p || q) && b in e)) {
          const d = f.getOwnPropertyDescriptor(a, b)

          f.defineProperty(e, b, { enumerable: !!d.enumerable, get: x.bind(a, b), set: D.bind(a, b) }); y[b] = !0
        }
      }); h = !0; if (p || q) {
        const E = f.setPrototypeOf || ([].__proto__ === Array.prototype ? function(b, d) {
          n(d); b.__proto__ = d; return b
        } : u)

        g && E(e, g) || (h = !1)
      } if (c.get || !h) {
        for (const r in a) {
          y[r] || f.defineProperty(e, r, { get: x.bind(a, r) })
        }
      }f.seal(a)
      f.seal(e); return A ? { proxy: e,
        revoke() {
          a = null; k = function(b) {
            throw new TypeError(`Cannot perform '${b}' on a proxy that has been revoked`)
          }
        } } : e
    } var f = Object; var w = !!f.create || !({ __proto__: null } instanceof f); var C = f.create || (w ? function(a) {
      n(a); return { __proto__: a }
    } : function(a) {
      function c() {}n(a); if (a === null) {
        throw new SyntaxError('Native Object.create is required to create objects with null prototype')
      } c.prototype = a; return new c()
    }); var B = f.getPrototypeOf || ([].__proto__ === Array.prototype ? function(a) {
      a = a.__proto__
      return l(a) ? a : null
    } : u); var t = function(a, c) {
      if (void 0 === (this && this instanceof t ? this.constructor : void 0)) {
        throw new TypeError("Constructor Proxy requires 'new'")
      } return v(a, c)
    }

    t.revocable = function(a, c) {
      return v(a, c, !0)
    }; return t
  } const z = typeof process !== 'undefined' && {}.toString.call(process) === '[object process]' || typeof navigator !== 'undefined' && navigator.product === 'ReactNative' ? global : self

  z.Proxy || (z.Proxy = m(), z.Proxy.revocable = z.Proxy.revocable)
}())
