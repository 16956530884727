import colors from './colors'
import fonts from './fonts'


export default {
  html: {
    height: '100% !important',
    fontSize: '62.5%',
    boxSizing: 'border-box',
    WebkitTextSizeAdjust: '100%',
    '*': {
      scrollbarWidth: 'none !important',
    },
    '*::-webkit-scrollbar': {
      display: 'none !important',
    },
  },
  body: {
    minHeight: '100% !important',
    padding: 0,
    margin: 0,
    background: colors.greyC4,
    color: colors.pureBlack,
    fontFamily: 'Arial Narrow, Arial, sans-serif',
    // @TODO maybe remove
    'html[lang=kr] &': {
      ...fonts.YDGothic,
    },
    'html[lang=jp] &': {
      ...fonts.TazuganeGothic,
    },
    'html[lang=cn] &': {
      ...fonts.MYingHei,
    },
    MozOsxFontSmoothing: 'grayscale',
    WebkitFontSmoothing: 'antialiased',
  },
  p: {
    margin: 0,
    padding: 0,
  },
  a: {
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  img: {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
  },
  'input, select, textarea, button': {
    '&:focus:not([keyboard-focus])': {
      outlineWidth: 0,
    },
  },
  'input::-ms-clear': {
    display: 'none',
  },
  '*, *:before, *:after': {
    boxSizing: 'inherit',
  },
  button: {
    padding: 0,
    border: 0,
  },
  input: {
    '&[type=text], &[type=password], &[type=email], &[type=number]': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '2rem',
      padding: '0 0.5rem',
      fontSize: '1.2rem',
      border: `0.1rem solid ${colors.pureBlack}`,
      borderRadius: 0,
      background: colors.greyC4,
      outline: '0 none',
      '&:focus-visible': {
        borderColor: colors.grey3B,
      },
    },
  },
  '#onetrust-consent-sdk#onetrust-consent-sdk': {
    '& #onetrust-banner-sdk': {
      background: colors.pureWhite,
      bottom: '0 !important',
      left: '0 !important',
      right: '0 !important',
      width: '100% !important',
      maxWidth: '76.2rem !important',
      border: `0.5rem solid ${colors.greyAA} !important`,
      borderImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyAgMAAABjUWAiAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAMUExURcTExNDQ0Kqqqt7e3ua3UhYAAAAwSURBVCjPY9gaigAPGPDy6v/DwFUgL2sVDEwF8hwYYEB0lDfKI8hDTT2oKYvYFAkAcqOU1dsOwY4AAAAASUVORK5CYII=) !important',
      borderImageSlice: '10% !important',
      borderImageWidth: '0.5rem !important',
      borderRadius: '0 !important',
      boxShadow: 'none !important',
    },
    '#onetrust-banner-sdk p, #onetrust-pc-sdk p, #ot-sdk-cookie-policy p': {
      fontFamily: 'Arial Narrow, Arial, sans-serif !important',
      fontSize: '1.1rem !important',
    },
    '#onetrust-banner-sdk .ot-sdk-button, #onetrust-banner-sdk button, #onetrust-pc-sdk .ot-sdk-button, #onetrust-pc-sdk button, #ot-sdk-cookie-policy .ot-sdk-button, #ot-sdk-cookie-policy button': {
      fontFamily: 'Arial Narrow, Arial, sans-serif !important',
    },
    '& #onetrust-accept-btn-handler, & #onetrust-reject-all-handler, & #onetrust-pc-btn-handler': {
      border: `4px outset ${colors.greyD0} !important`,
      borderRadius: 0,
      fontFamily: 'Arial Narrow, Arial, sans-serif !important',
      textTransform: 'uppercase !important',
      fontSize: '1.2rem !important',
      fontWeight: 'normal !important',
    },
    '& #onetrust-pc-btn-handler': {
      border: `4px outset ${colors.pureBlack} !important`,
    },
  },
  '@media (min-width: 760px)': {
    '#onetrust-consent-sdk#onetrust-consent-sdk': {
      '& #onetrust-banner-sdk': {
        left: '40rem !important',
        right: 'auto !important',
        bottom: '2.4rem !important',
        transform: 'translateX(-50%) !important',
      },
    },
  },
}
