import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { INLINES } from '@contentful/rich-text-types'
import { createUseStyles } from 'react-jss'
import TestRenderer from 'react-test-renderer'

import withMemo from '../../decorators/withMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const RichText = (props) => {
  const { json, className } = props
  const classes = useStyles(props)

  const renderRichtext = () => documentToReactComponents(
    {
      nodeType: 'document',
      ...json,
    },
    {
      renderNode: {
        [INLINES.HYPERLINK]: (node, children) => {
          const child = TestRenderer.create(children).toJSON()

          return (
            ((typeof child !== 'string' && child && child.children)
                || typeof child === 'string') && (
                <a
                  className={classes.a}
                  href={node.data.uri}
                  target="_blank"
                  rel="noreferrer"
                >
                  {children}
                </a>
            )
          )
        },
      },
      renderText: (text) => <>{text}</>,
    }
  )

  return <div className={classNames(classes.container, className)}>{renderRichtext()}</div>
}

RichText.propTypes = {
  className: PropTypes.string,
  json: PropTypes.object, // eslint-disable-line react/forbid-prop-types
}

RichText.defaultProps = {
  className: null,
  json: null,
}

export default withMemo()(RichText)
