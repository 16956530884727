/* eslint-disable complexity */
import React, { useCallback, useEffect, useRef } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { Button } from '..'

import useStyles from './styles'


const PasswordForm = (props) => {
  const classes = useStyles(props)
  const {
    className,
    textButton,
    textLabel,
    submitHandler,
    textError,
  } = props

  // REFS
  const inputs = useRef([])

  // STATES

  // MEMO

  // ANIMATIONS

  // HANDLERS
  // - on change, switch to next
  const handleChange = useCallback((e, index) => {
    const target = e.target
    const maxLength = target?.maxLength

    if (target && target.value.length >= maxLength && inputs.current[index + 1]) {
      inputs.current[index + 1].value = ''
      inputs.current[index + 1].focus()
    }
  }, [])

  // - submit
  const handleFormSubmit = useCallback((e) => {
    if (submitHandler) {
      submitHandler(e)
    }
  }, [submitHandler])

  // EFECTS
  useEffect(() => {

  }, [])

  // RETURN

  return (
    <div
      className={cx(classes.container, className)}
    >
      <form
        onSubmit={handleFormSubmit}
        id="Password"
        className={classes.form}
      >
        {textLabel && (
          <label
            className={classes.label}
            htmlFor="Password-0"
          >
            {textLabel}
          </label>
        )}
        <div className={classes.inputs}>
          {[...Array(4)].map((_e, index) => (
            <input
              key={`PasswordForm-input-${index}`}
              name="password[]"
              type="text"
              autoComplete="off"
              id={`Password-${index}`}
              maxLength="1"
              onInput={(e) => handleChange(e, index)}
              ref={(el) => {
                inputs.current[index] = el
              }}
            />
          ))}
        </div>
        {textError && (
          <p className={classes.errors}>{textError}</p>
        )}
        <div className={classes.actions}>
          <button
            type="submit"
            className={cx(classes.action)}
          >
            <Button>
              {textButton ?? 'Ok'}
            </Button>
          </button>
        </div>
      </form>
    </div>
  )
}

PasswordForm.propTypes = {
  className: PropTypes.string,
  textButton: PropTypes.string,
  textLabel: PropTypes.string,
  textError: PropTypes.string,
  submitHandler: PropTypes.func,
}

PasswordForm.defaultProps = {
  className: null,
  textButton: null,
  textLabel: null,
  textError: null,
  submitHandler: (e) => null,
}

export default PasswordForm
