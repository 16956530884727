import { createUseStyles } from 'react-jss'

import { colors, desktop } from '../../theme'


export default createUseStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '32rem',
    fontSize: '1.1rem',
    '& input.is-error': {
      borderColor: colors.redError,
    },
  },
  form: {
    width: '100%',
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
  },
  control: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    '& label': {
      textTransform: 'uppercase',
      minWidth: '3rem',
      whiteSpace: 'nowrap',
    },
    '& input': {
      flex: '1 1 auto',
    },
    '& input:not(:first-child)': {
      marginLeft: '1rem',
    },
  },
  row: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginLeft: '-0.5rem',
    marginRight: '-0.5rem',
    '&:not(:first-child)': {
      marginTop: '1rem',
    },
    '& > *': {
      marginLeft: '0.5rem',
      marginRight: '0.5rem',
    },
    '& $control': {
      flex: '1 1 auto',
      width: 'auto',
    },
    '& $control input': {
      width: '10px',
    },
  },
  civilityLabel: {
    textTransform: 'uppercase',
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
  },
  radios: {
    display: 'flex',
    flexFlow: 'row wrap',
    marginLeft: '-0.7rem',
    marginRight: '-0.7rem',
    '& > *': {
      marginTop: '1rem',
      marginLeft: '0.7rem',
      marginRight: '0.7rem',
    },
  },
  radio: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '1.4rem',
    '& input': {
      position: 'absolute',
      opacity: 0,
    },
    '& label': {
      paddingLeft: '2rem',
      textTransform: 'uppercase',
    },
    '& label::before, & label::after': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: 0,
    },
    '& label::before': {
      width: '1.4rem',
      height: '1.4rem',
      border: `0.1rem solid ${colors.pureBlack}`,
      background: colors.greyC4,
    },
    '& input:checked ~ label::after': {
      width: '0.8rem',
      height: '0.8rem',
      background: colors.pureBlack,
      transform: 'translate(0.3rem, 0.3rem)',
    },
    '$radios.is-error & label::before': {
      borderColor: colors.redError,
    },
  },
  legals: {
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
  },
  optinContainer: {
    position: 'relative',
    '&:not(:first-child)': {
      marginTop: '1rem',
    },
    '& input': {
      position: 'absolute',
      opacity: 0,
    },
    '& label': {
      fontSize: '0.9rem',
      display: 'block',
      paddingLeft: '2rem',
    },
    '& label::before, & label::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    '& label::before': {
      width: '1.4rem',
      height: '1.4rem',
      border: `0.1rem solid ${colors.pureBlack}`,
      background: colors.greyC4,
    },
    '& input:checked ~ label::after': {
      width: '0.8rem',
      height: '0.8rem',
      background: colors.pureBlack,
      transform: 'translate(0.3rem, 0.3rem)',
    },
    '&.is-error label::before': {
      borderColor: colors.redError,
    },
  },
  error: {
    color: colors.redError,
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  errorMessage: {
    color: colors.redError,
    '&:not(:first-child)': {
      marginTop: '2rem',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
  },
  action: {
    width: '11rem',
  },
  ...desktop({
    container: {
      maxWidth: '46rem',
      fontSize: '1.4rem',
    },
    control: {
      '& input': {
        height: '2.5rem',
        fontSize: '1.4rem',
      },
    },
    optinContainer: {
      '& label': {
        fontSize: '1.1rem',
      },
    },
  }),
})
