import { Helmet } from 'react-helmet'
import React, { PureComponent } from 'react'


export default class DefaultHead extends PureComponent {

  render() {
    return (
      <Helmet>
        <meta
          httpEquiv="X-UA-Compatible"
          content="IE=edge"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
      </Helmet>
    )
  }

}
