import { fontsFaces } from './fonts'
import keyframes from './keyframes'
import reset from './reset'


export { default as fonts } from './fonts'
export { default as reset } from './reset'
export { default as colors } from './colors'
export { default as zIndexes } from './zIndexes'
export {
  default as sizes,
  maxSizes,
  desktopMedia,
  desktopMediaQuery,
  tabletMedia,
  tabletMediaQuery,
  fullDesktopMedia,
  fullDesktopMediaQuery,
  maxDesktopMedia,
  maxDesktopMediaQuery,
  hdmiMedia,
  hdmiMediaQuery
} from './sizes'
export { default as textStyles } from './textStyles'

export {
  transition,
  svgForce,
  svgGlobal,
  svgColor,
  alpha,
  tablet,
  notTablet,
  desktop,
  notDesktop,
  fullDesktop,
  notFullDesktop,
  maxDesktop,
  notMaxDesktop,
  hdmi,
  notHdmi,
  placeholder,
  aspectRatio,
  important
} from './mixins'

export { accessibleHiddenText, accessibleHidden } from './accessibility'

export const global = {
  '@global': {
    ...keyframes,
    ...reset,
    ...fontsFaces,
  },
}
