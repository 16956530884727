import { declareFont, defineFont, fontWeights } from '../utils/FontUtils'


export const declaration = {
  MYingHei: {
    basename: 'MYingHeiPRC-W4',
    fontFamily: 'MYingHeiPRC-W4',
    fontWeight: fontWeights.regular,
    fallback: 'Microsoft YaHei, PingFang SC, Microsoft YaHei Regular, SimHei, SimHei Regular, Heiti SC, Arial, Helvetica, sans-serif',
  },
  MYingHeiBold: {
    basename: 'MYingHeiPRC-W7',
    fontFamily: 'MYingHeiPRC-W7',
    fontWeight: fontWeights.regular,
    fallback: 'Microsoft YaHei, PingFang SC, Microsoft YaHei Regular, SimHei, SimHei Regular, Heiti SC, Arial, Helvetica, sans-serif',
  },
  TazuganeGothic: {
    basename: 'TazuganeGothicStdN-Regular',
    fontFamily: 'TazuganeGothicStdN-Regular',
    fontWeight: fontWeights.regular,
    fallback: 'Hiragino Kaku Gothic ProN, Meiryo, MS PGothic, Arial, Helvetica, sans-serif',
  },
  TazuganeGothicBold: {
    basename: 'TazuganeGothicStdN-Bold',
    fontFamily: 'TazuganeGothicStdN-Bold',
    fontWeight: fontWeights.regular,
    fallback: 'Hiragino Kaku Gothic ProN, Meiryo, MS PGothic, Arial, Helvetica, sans-serif',
  },
  YDGothic: {
    basename: 'YDGothic520',
    fontFamily: 'YDGothic520',
    fontWeight: fontWeights.regular,
    fallback: 'Clearly_Gothic, Hiragino Kaku Gothic ProN, Meiryo, MS PGothic, Arial, Helvetica, sans-serif',
  },
  YDGothicBold: {
    basename: 'YDGothic550',
    fontFamily: 'YDGothic550',
    fontWeight: fontWeights.regular,
    fallback: 'Clearly_Gothic, Hiragino Kaku Gothic ProN, Meiryo, MS PGothic, Arial, Helvetica, sans-serif',
  },
}

export default {
  MYingHei: defineFont(declaration.MYingHei),
  MYingHeiBold: defineFont(declaration.MYingHeiBold),
  TazuganeGothic: defineFont(declaration.TazuganeGothic),
  TazuganeGothicBold: defineFont(declaration.TazuganeGothicBold),
  YDGothic: defineFont(declaration.YDGothic),
  YDGothicBold: defineFont(declaration.YDGothicBold),
}

export const fontsFaces = {
  '@font-face': Object.values(declaration).map(declareFont),
}
