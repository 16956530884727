/* eslint-disable complexity */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/media-has-caption */

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Loadable from '@loadable/component'

import { RichText } from '..'

import './plyr.css'
import useStyles from './styles'


const Plyr = Loadable(() => import('plyr-react'))


const Video = (props) => {
  const classes = useStyles(props)
  const { title, json, video } = props
  const [playerInstance, setPlayerInstance] = useState(null)
  // STATE and REFS and other variables
  const playerOptions = useMemo(() => ({
    // default
    loadSprite: true,
    hideControls: false,
    clickToPlay: true,
    fullscreen: {
      enabled: true,
      fallback: true,
      iosNative: false,
      container: '.is-plyr-container',
    },
    controls: ['play', 'progress', 'mute'],
    volume: 1,
  }), [])


  // RENDERERS
  // - plyr
  const renderPlayer = useMemo(() => video && window && (
    <Plyr
      ref={(plyr) => setPlayerInstance(plyr)}
      source={{
        type: 'video',
        sources: [{
          src: video.url,
          type: video.contentType,
        }],
      }}
      options={playerOptions}
      preload="metadata"
    />
  ), [playerOptions, video])

  const track = useCallback((plyr, action) => {
    if (window) {
      window.dataLayer = window.dataLayer || []

      let stackPercent = 0
      const percent = (plyr?.currentTime * 100) / plyr?.duration

      if (percent < 25) {
        stackPercent = 0
      } else if (percent >= 25 && percent <= 50) {
        stackPercent = 25
      } else if (percent > 50 && percent <= 75) {
        stackPercent = 50
      } else if (percent > 75 && percent <= 90) {
        stackPercent = 75
      } else {
        stackPercent = 100
      }

      window.dataLayer.push({
        event: 'video',
        videoStatus: action,
        videoTitle: title,
        videoUrl: plyr?.source,
        videoDuration: plyr?.currentTime ?? 0,
        videoPercent: stackPercent,
      })
    }
  }, [title])

  useEffect(() => {
    if (playerInstance && playerInstance.plyr && playerInstance.plyr.play) {
      playerInstance.plyr.on('play', (event) => track(event.detail.plyr, 'play'))
      playerInstance.plyr.on('pause', (event) => track(event.detail.plyr, 'pause'))
      playerInstance.plyr.on('seeked', (event) => track(event.detail.plyr, 'seeked'))
      playerInstance.plyr.on('ended', (event) => track(event.detail.plyr, 'ended'))

      return () => {
        playerInstance.plyr.off('play')
        playerInstance.plyr.off('pause')
        playerInstance.plyr.off('seeked')
        playerInstance.plyr.off('ended')
      }
    }
  }, [playerInstance, track])

  return (
    <article
      className={classes.container}
    >
      <div className={cx(classes.video, 'is-plyr-container')}>
        {renderPlayer}
      </div>
      {title && <h2 className={classes.title}>{title}</h2>}
      {json && (
        <RichText
          className={cx(classes.text)}
          json={json}
        />
      )}
    </article>
  )
}

Video.propTypes = {
  title: PropTypes.string,
  json: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  video: PropTypes.shape({
    contentType: PropTypes.string,
    url: PropTypes.string,
  }),
}

Video.defaultProps = {
  title: null,
  json: null,
  video: null,
}

export default Video
