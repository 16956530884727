/* eslint-disable react/forbid-prop-types */
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import withMemo from '../../decorators/withMemo'
import Layout from '../../components/Layout'

import useStyles from './styles'


const PageTemplate = (props) => {
  const classes = useStyles(props)
  const { className, children, header, navigation, footer } = props

  return (
    <Layout
      className={cx(classes.container, className, navigation && 'is-page__with-nav')}
      header={header}
      navigation={navigation}
      footer={footer}
    >
      {children}
    </Layout>
  )
}

export const PressTemplatePropTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  header: PropTypes.node,
  navigation: PropTypes.node,
  footer: PropTypes.node,
}

PageTemplate.propTypes = PressTemplatePropTypes

PageTemplate.defaultProps = {
  children: null,
  className: null,
  header: null,
  navigation: null,
  footer: null,
}

export default withMemo()(PageTemplate)
