import { createUseStyles } from 'react-jss'

import { desktop } from '../../theme'


export default createUseStyles({
  container: {
    textAlign: 'center',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
  },
  form: {
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
  },
  label: {
    fontSize: '1.8rem',
    textTransform: 'uppercase',
  },
  input: {
    '&:not(:first-child)': {
      marginTop: '1.5rem',
    },
    '& input': {
      width: '100%',
      maxWidth: '20rem',
      margin: '0 auto',
    },
  },
  actions: {
    marginTop: 'min(10rem, 15vh)',
  },
  action: {
    width: '11rem',
  },
  ...desktop({
    actions: {
      marginTop: '6.6rem',
    },
    input: {
      '& input': {
        height: '2.5rem',
        fontSize: '1.6rem',
      },
    },
  }),
})
