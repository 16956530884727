/*
 * This project use mobile first integration
 * Default media is mobile
 */

const sizes = {
  mobile: 576,
  tablet: 768,
  desktop: 992,
  fullDesktop: 1200,
  maxDesktop: 1440,
  hdmi: 1900,
}

// Keep it as small as possible
export const maxSizes = {}

export default sizes

// Media Queries

export const tabletMedia = `(min-width: ${sizes.tablet}px)`
export const tabletMediaQuery = `@media ${tabletMedia}`

export const notTabletMedia = `(max-width: ${sizes.tablet - 1}px)`
export const notTabletMediaQuery = `@media ${notTabletMedia}`

export const desktopMedia = `(min-width: ${sizes.desktop}px)`
export const desktopMediaQuery = `@media ${desktopMedia}`

export const notDesktopMedia = `(max-width: ${sizes.desktop - 1}px)`
export const notDesktopMediaQuery = `@media ${notDesktopMedia}`

export const fullDesktopMedia = `(min-width: ${sizes.fullDesktop}px)`
export const fullDesktopMediaQuery = `@media ${fullDesktopMedia}`

export const notFullDesktopMedia = `(max-width: ${sizes.fullDesktop - 1}px)`
export const notFullDesktopMediaQuery = `@media ${notFullDesktopMedia}`

export const maxDesktopMedia = `(min-width: ${sizes.maxDesktop}px)`
export const maxDesktopMediaQuery = `@media ${maxDesktopMedia}`

export const notMaxDesktopMedia = `(max-width: ${sizes.maxDesktop - 1}px)`
export const notMaxDesktopMediaQuery = `@media ${notMaxDesktopMedia}`

export const hdmiMedia = `(min-width: ${sizes.hdmi}px)`
export const hdmiMediaQuery = `@media ${hdmiMedia}`

export const notHdmiMedia = `(max-width: ${sizes.hdmi - 1}px)`
export const notHdmiMediaQuery = `@media ${notHdmiMedia}`
