import * as PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { graphql } from 'gatsby'
import { useDispatch } from 'react-redux'

import App from '../../components/App'
import { withMemo } from '../../decorators'
import Blocks from '../../relay/Blocks'
import { Header, Navigation, Popin } from '../../components'
import PageTemplate from '../../templates/Page'
import { Auth } from '../../relay'
import { actions as AppActions } from '../../redux/app/redux'
import { NavigationTransformer, SeoTransformer } from '../../relay/transformers'
import { useIntl } from '../../intl/intl'
import useConfiguration from '../../hooks/useConfiguration'
import PasswordForm from '../../components/PasswordForm'


const Page = (props) => {
  const {
    data,
    pageContext,
    location,
  } = props

  const [init, setInit] = useState(false)
  const [password, setPassword] = useState('')
  const [isOpenPassword, setOpenPassword] = useState(false)
  const kitLink = useRef()

  const t = useIntl()
  const page = data?.contentful?.page ?? null
  const configuration = useConfiguration(pageContext?.configuration)
  const masterPassword = useMemo(() => configuration?.configuration?.password, [configuration])
  const layoutProps = useMemo(() => SeoTransformer(page?.seo ?? pageContext?.configuration?.seo, pageContext?.language ?? null, location), [page, pageContext, location])
  const dispatch = useDispatch()
  const isPasswordValid = useMemo(() => password === masterPassword, [password, masterPassword])
  const [passwordError, setPasswordError] = useState('')

  useEffect(() => {
    dispatch(AppActions.init())
  }, [dispatch])

  // close password
  const handleClosePassword = useCallback(() => {
    setOpenPassword(false)
  }, [])

  // launch download if password is okay
  const handleDownload = useCallback(() => {
    if (kitLink.current) {
      kitLink.current.click()
    }
  }, [kitLink])

  // on enter password
  const handleSubmitPassword = useCallback((e) => {
    e.preventDefault()

    const passwordFields = Array.from(e.target.elements['password[]'])

    if (passwordFields) {
      const pass = passwordFields.map((input) => input.value).join('')

      setPassword(pass)

      if (pass === masterPassword) {
        handleDownload()
        setOpenPassword(false)
        setPasswordError('')
      } else {
        setPasswordError('Mot de passe invalide')
      }
    }
  }, [handleDownload, masterPassword])

  // on click on download
  const handleClickDownload = useCallback((e) => {
    e.preventDefault()
    if (isPasswordValid) {
      handleDownload()
    } else {
      setOpenPassword(true)
    }
  }, [handleDownload, isPasswordValid])

  const propsPasswordForm = useMemo(() => (
    {
      textButton: t('downloadTextButton'),
      textLabel: t('downloadTextLabel'),
    }
  ), [t])

  const navigationProps = useMemo(() => NavigationTransformer(configuration, t, handleClickDownload, page.slug, page.template), [configuration, t, handleClickDownload, page])

  useEffect(() => {
    if (page.context === 'Pres-Show-Only' && !configuration.isBeforeShow) {
      // window.location.href='/'
    } else if (page.context === 'After-Show-Only' && !configuration.isAfterShow) {
      // window.location.href='/'
    } else {
      setInit(true)
    }
  }, [configuration, page])

  return (
    <>
      {init
          && (
          <App {...layoutProps}>
            <PageTemplate
              header={<Header {...navigationProps?.headerProps} />}
              navigation={<Navigation {...navigationProps?.navProps} />}
            >
              <Blocks blocks={page.contentCollection.items} />
              {configuration.isAfterShow
            && (
            <>
              <Popin
                isOpen={isOpenPassword}
                isClosable
                closeHandler={handleClosePassword}
              >
                <PasswordForm
                  {...propsPasswordForm}
                  submitHandler={(e) => handleSubmitPassword(e)}
                  textError={passwordError}
                />
              </Popin>
              <a
                ref={kitLink}
                style={{ opacity: 0, position: 'absolute', height: 0, width: 0, display: 'block' }}
                href="/source_videos/press-kit.zip"
              >
                {t('download')}
              </a>
            </>
            )}
            </PageTemplate>
          </App>
          )}
    </>
  )
}

Page.propTypes = {
  // eslint-disable-next-line
  location: PropTypes.object,
  // eslint-disable-next-line
  data: PropTypes.object,
  // eslint-disable-next-line
  pageContext: PropTypes.object,
}

Page.defaultProps = {
  location: null,
  data: null,
  pageContext: null,
}

export default withMemo()(Page)


export const query = graphql`
  query($id: String!, $preview: Boolean, $locale: String) {
    contentful {
      page(id: $id, preview: $preview, locale: $locale) {
        context
        template
        slug
        seo{
          title
          description
          picture {
            url
            localAsset{
              publicURL
            }
          }
        }
        contentCollection(limit:1) {
          total
          items {
            __typename
            ... on Contentful_BlockHtml{
              html{json}
            }
            ... on Contentful_BlockShow{
              description{json}
            }
            ... on Contentful_BlockMedia{
              contentCollection(limit:250) {
                items {
                  __typename
                  ... on Contentful_BlockImage{
                    subtitle
                    image {
                      url
                      localAsset {
                        childImageSharp {
                          fluid(maxWidth: 1024, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                  ... on Contentful_BlockVideo {
                    subtitle
                    description{json}
                    video {
                      url
                      contentType
                      localAsset {
                        publicURL
                      }
                    }
                  }
                }
              }
            }
            ... on Contentful_BlockGlitch{
              title
            }
            
          }
        }
      }
    }
  }
`
