// import colors from './colors'


export default {
  '@keyframes bounce': {
    from: {
      transform: 'translateY(-1.5rem)',
    },
    to: {
      transform: 'translateY(0)',
    },
  },
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  '@keyframes pulsingLoadingBG': {
    '0%': {
      backgroundColor: 'lightgrey',
    },
    '50%': {
      backgroundColor: 'grey',
    },
    '100%': {
      backgroundColor: 'lightgrey',
    },
  },
}
