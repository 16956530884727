import React, { useMemo, useCallback } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import Link from '../Link'
import CustomScroll from '../CustomScroll'

import useStyles from './styles'


const Navigation = (props) => {
  const classes = useStyles(props)
  const {
    className,
    linksTop,
    linksBottom,
    linksLegal,
  } = props

  const renderLinks = useCallback((list, thisClassName) => list?.length > 0 && (
    <ul className={cx(classes.list, `is-${thisClassName}`)}>
      {list.map((item, index) => (
        <li
          key={`navigation-${thisClassName}-${index}`}
          className={classes.item}
        >
          {item.title && <strong className={classes.title}>{item.title}</strong>}
          {item.links && (
            <ul className={classes.links}>
              {item.links.map((link, i) => (
                <li
                  key={`navigation-${thisClassName}-${index}-${i}`}
                  className={cx(classes.linkContainer, link.isCurrent && 'is-current')}
                >
                  <Link
                    className={cx(classes.link)}
                    {...link}
                  />
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  ), [classes])

  const isEmptyOnMobile = useMemo(
    () => (linksTop === null || linksTop?.length === 0) && (linksBottom === null || linksBottom?.length === 0),
    [linksBottom, linksTop]
  )

  const renderTop = useMemo(() => renderLinks(linksTop, 'top'), [linksTop, renderLinks])
  const renderBottom = useMemo(() => renderLinks(linksBottom, 'bottom'), [linksBottom, renderLinks])
  const renderLegals = useMemo(() => renderLinks(linksLegal, 'legals'), [linksLegal, renderLinks])

  return (
    <nav className={cx(className, classes.container, isEmptyOnMobile && 'is-empty-mobile')}>
      <CustomScroll
        className={classes.scroller}
      >
        <div
          className={classes.wrapper}
        >
          {renderTop}
          {renderBottom}
          {renderLegals}
        </div>
      </CustomScroll>
    </nav>
  )
}

Navigation.propTypes = {
  className: PropTypes.string,
  linksTop: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.shape(
          {
            isCurrent: PropTypes.bool,
            ...Link.propTypes,
          }
        )
      ),
    })
  ),
  linksBottom: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.shape(
          {
            isCurrent: PropTypes.bool,
            ...Link.propTypes,
          }
        )
      ),
    })
  ),
  linksLegal: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.shape(
          {
            isCurrent: PropTypes.bool,
            ...Link.propTypes,
          }
        )
      ),
    })
  ),
}

Navigation.defaultProps = {
  className: null,
  linksTop: null,
  linksBottom: null,
  linksLegal: null,
}

export default Navigation
