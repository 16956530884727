import * as appConfiguration from '../../configuration'


export default (seo, language, location) => ({
  seo: {
    lang: language ?? null,
    title: seo?.title ?? '',
    description: seo?.description?.description ?? '',
    image: appConfiguration.app.url + seo?.picture?.localFile?.publicURL ?? '',
    url: location?.href ?? '',
  },
})
