import { createUseStyles } from 'react-jss'

import { colors, desktop } from '../../theme'


export default createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '3rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    border: `4px outset ${colors.greyD0}`,
    background: colors.greyC4,
    fontSize: '1rem',
    textTransform: 'uppercase',
    color: colors.pureBlack,
    'a[href]:hover &, button:not(:disabled):hover &': {
      cursor: 'pointer',
      borderColor: colors.greyDE,
      background: colors.greyD0,
    },
    'a[href]:active &, button:not(:disabled):active &': {
      cursor: 'pointer',
      borderStyle: 'inset',
    },
  },
  ...desktop({}),
})
