/* eslint-disable no-console */

import React from 'react'

import { Medias } from '../../components'
import Blocks from '../Blocks'


export default (props) => (
  <Medias>
    <Blocks blocks={props.contentCollection.items} />
  </Medias>
)
