import { createUseStyles } from 'react-jss'

import { colors, desktop } from '../../theme'


export default createUseStyles({
  container: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexFlow: 'column nowrap',
    maxWidth: '80rem',
    overflow: 'hidden',
  },
  main: {
    flex: '1 0 auto',
    background: colors.pureWhite,
    display: 'flex',
    flexFlow: 'column nowrap',
    paddingBottom: '2rem',
  },
  contentScroller: {
    flex: '1 1 auto',
    display: 'flex',
    flexFlow: 'column nowrap',
    height: 'calc(100% - 8rem)',
  },
  ...desktop({
    container: {
      // for ie11.......
      width: '80rem',

    },
    main: {
      paddingLeft: '16rem',
    },
  }),
})
