export default {
  image: {
    display: 'flex',
    width: '100%',
    height: '100%',
    background: '#F8F5F6',
    '& img': {
      maxWidth: '100%',
      height: 'auto',
      objectFit: 'contain',
      fontFamily: "'object-fit: contain;' !important",
    },
  },
}
