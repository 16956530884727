/* eslint-disable camelcase */
/* eslint-disable react/jsx-max-props-per-line */
import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'


const Seo = (props) => {
  const { title, description, image, twitter_card, url, type, twitter_account, lang, robots } = props

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url} />
      {/* <meta property="og:locale" content={isoCode} /> */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content={twitter_card} />
      {robots && <meta property="robots" content={robots} />}
      {image && <meta property="og:image" content={image} />}
      {image && <meta name="twitter:image" content={image} />}
      {twitter_account && <meta property="twitter:site" content={twitter_account} />}
      {twitter_account && <meta name="twitter:creator" content={twitter_account} />}
      {lang && <html lang={lang} />}
    </Helmet>
  )
}

Seo.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  type: PropTypes.string,
  twitter_card: PropTypes.string,
  twitter_account: PropTypes.string,
  lang: PropTypes.string,
  robots: PropTypes.string,
}

Seo.defaultProps = {
  description: '',
  title: '',
  url: '',
  image: null,
  type: 'website',
  twitter_card: 'summary_large_image',
  twitter_account: null,
  lang: null,
  robots: null,
}

export default Seo
