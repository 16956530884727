import { createUseStyles } from 'react-jss'

import { colors, desktop, zIndexes } from '../../theme'


export default createUseStyles({
  container: {
    position: 'relative',
    zIndex: zIndexes.navigation,
    paddingBottom: '0.5rem',
    textAlign: 'center',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 0,
      border: 0,
      borderTop: `0.5rem solid ${colors.greyC4}`,
      borderImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyAgMAAABjUWAiAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAMUExURcTExNDQ0Kqqqt7e3ua3UhYAAAAwSURBVCjPY9gaigAPGPDy6v/DwFUgL2sVDEwF8hwYYEB0lDfKI8hDTT2oKYvYFAkAcqOU1dsOwY4AAAAASUVORK5CYII=)',
      borderImageSlice: '10%',
      borderImageWidth: '0.5rem 0 0',
    },
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    flexFow: 'row nowrap',
    height: '8rem',
  },
  title: {
    flex: '0 0 auto',
    '&, & > a': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'column nowrap',
    },
    width: '50%',
    margin: 0,
    fontWeight: 'bold',
    fontSize: '1.6rem',
    lineHeight: '1.2',
    textTransform: 'uppercase',
    borderRight: `0.5rem solid ${colors.greyC4}`,
    borderImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyAgMAAABjUWAiAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAMUExURcTExNDQ0Kqqqt7e3ua3UhYAAAAwSURBVCjPY9gaigAPGPDy6v/DwFUgL2sVDEwF8hwYYEB0lDfKI8hDTT2oKYvYFAkAcqOU1dsOwY4AAAAASUVORK5CYII=)',
    borderImageSlice: '10%',
    borderImageWidth: '0 0.5rem 0 0',
    background: colors.pureWhite,
    '& > a': {
      color: 'inherit',
    },
  },
  content: {
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column nowrap',
    width: '50%',
    fontSize: '1.6rem',
    lineHeight: '1.2',
    textTransform: 'uppercase',
    background: colors.pureWhite,
    '&.is-long': {
      fontSize: '1.3rem',
    },
    '& p': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'column nowrap',
    },
  },
  ...desktop({
    container: {
      paddingBottom: 0,
      '&::after': {
        display: 'none',
      },
    },
    title: {
      position: 'fixed',
      height: '8rem',
      width: '16rem',
    },
    content: {
      flex: '1 0 auto',
      minHeight: '8rem',
      paddingLeft: '16rem',
      borderBottom: `0.5rem solid ${colors.greyC4}`,
      borderImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyAgMAAABjUWAiAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAMUExURcTExNDQ0Kqqqt7e3ua3UhYAAAAwSURBVCjPY9gaigAPGPDy6v/DwFUgL2sVDEwF8hwYYEB0lDfKI8hDTT2oKYvYFAkAcqOU1dsOwY4AAAAASUVORK5CYII=)',
      borderImageSlice: '10%',
      borderImageWidth: '0 0 0.5rem 0',
      fontSize: '2rem',
      '&.is-long': {
        fontSize: '1.8rem',
      },
      '& p': {
        display: 'block',
      },
    },
  }),
})
