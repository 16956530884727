import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import useStyles from './styles'


const Medias = (props) => {
  const classes = useStyles(props)
  const {
    className,
    children,
    isReverse,
  } = props


  return (
    <section className={cx(className, classes.container, isReverse === true && 'is-reverse')}>
      {children}
    </section>
  )
}

Medias.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isReverse: PropTypes.bool,
}

Medias.defaultProps = {
  className: null,
  children: null,
  isReverse: false,
}

export default Medias
