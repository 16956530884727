/* eslint-disable react/forbid-prop-types */
import cx from 'classnames'
import * as PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'

import withMemo from '../../decorators/withMemo'
import { colors } from '../../theme'
import { mergeStyles } from '../../utils/StyleUtils'
import Link from '../Link'

import { icons, iconsKeys } from './Icon.assets'
import useStyles from './styles'

/** @param {IconProps} props */
function Icon(props) {
  const {
    className,
    classname,
    style,
    icon,
    color,
    link,
    route,
    routeParams,
    target,
    onClick,
    seoTitle,
    iconProps,
    classes: classesProp,
  } = props

  const iconComponent = typeof icon === 'string' ? icons[icon] : icon

  const classesComp = useStyles(props)
  const classesIcon = iconComponent?.useStyles(props)

  const classes = useMemo(() => mergeStyles(mergeStyles(classesComp, classesIcon), classesProp), [
    classesComp,
    classesIcon,
    classesProp,
  ])

  const renderAsLink = useCallback(
    (IconComponent) => (
      <div
        className={cx(classes.container, classes.icon, className, classname, color)}
        style={style ?? {}}
        onClick={onClick ?? undefined}
        role="presentation"
      >
        <Link
          link={link}
          route={route}
          routeParams={routeParams}
          title={seoTitle}
          target={target}
          rel="noreferrer"
        >
          <IconComponent {...iconProps} />
        </Link>
      </div>
    ),
    [className, classes.container, classes.icon, classname, color, iconProps, link, onClick, route, routeParams, seoTitle, style, target]
  )

  const renderDefault = useCallback(
    (IconComponent) => (
      <span
        className={cx(classes.container, classes.icon, className, classname, color, onClick && classes.hasClick)}
        style={style ?? {}}
        onClick={onClick ?? undefined}
        role="presentation"
      >
        <IconComponent {...iconProps} />
      </span>
    ),
    [className, classes.container, classes.hasClick, classes.icon, classname, color, iconProps, onClick, style]
  )

  if (!icon) {
    return null
  }

  if (!iconComponent) {
    return null
  }

  return link || route ? renderAsLink(iconComponent) : renderDefault(iconComponent)
}

export const IconKeyPropTypes = PropTypes.oneOf(Object.values(iconsKeys))

const IconPropTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  icon: PropTypes.oneOfType([PropTypes.func, IconKeyPropTypes]),
  color: PropTypes.oneOf(Object.values(colors)),
  style: PropTypes.objectOf(PropTypes.string),
  classname: PropTypes.string,
  className: PropTypes.string,
  iconProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  onClick: PropTypes.func,
  link: PropTypes.string,
  route: PropTypes.string,
  routeParams: PropTypes.object,
  target: PropTypes.string,
  seoTitle: PropTypes.string,
}

Icon.propTypes = IconPropTypes

/** @type {IconProps} */
Icon.defaultProps = {
  classes: null,
  icon: null,
  style: null,
  color: null,
  classname: null,
  className: null,
  onClick: null,
  link: null,
  route: null,
  routeParams: {},
  target: null,
  seoTitle: null,
  iconProps: {
    className: null,
  },
}

export default withMemo()(Icon)
