export default {
  container: {
    '& > *': {
      margin: 0,
    },
    '& > * + *': {
      marginTop: '1rem',
    },
    '& ol': {
      padding: 0,
      listStyle: 'none',
      '& > *': {
        position: 'relative',
        margin: 0,
        paddingLeft: '1.8em',
        counterIncrement: 'list-number',
      },
      '& > *::before': {
        content: 'counter(list-number) ". "',
        position: 'absolute',
        top: 0,
        left: 0,
      },
      '& > * + *': {
        marginTop: '0.2rem',
      },
    },
    '& ul': {
      padding: 0,
      listStyle: 'disc inside',
      '& > *': {
        margin: 0,
      },
      '& > * + *': {
        marginTop: '0.2rem',
      },
    },
  },
}
