import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
// import _debounce from 'lodash/debounce'

import CustomScroll from '../CustomScroll'

import useStyles from './styles'


const Layout = (props) => {
  const classes = useStyles()
  const { children, header, navigation, footer, className } = props

  return (
    <div className={cx(classes.container, className)}>
      {header}
      {navigation}
      <CustomScroll
        className={classes.contentScroller}
        secureYScroll
      >
        <main className={classes.main}>
          {children}
        </main>
      </CustomScroll>
      {footer}
    </div>
  )
}

Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  header: PropTypes.node,
  navigation: PropTypes.node,
  footer: PropTypes.node,
}
Layout.defaultProps = {
  className: null,
  children: null,
  header: null,
  navigation: null,
  footer: null,
}

export default Layout
