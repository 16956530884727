import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { RichText } from '..'

import useStyles from './styles'


const Note = (props) => {
  const classes = useStyles(props)
  const {
    className,
    json,
  } = props


  return (
    <RichText
      className={cx(classes.container, className)}
      json={json}
    />
  )
}

Note.propTypes = {
  className: PropTypes.string,
  json: PropTypes.object, // eslint-disable-line react/forbid-prop-types
}

Note.defaultProps = {
  className: null,
  json: null,
}

export default Note
