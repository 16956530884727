import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Scrollbar } from 'react-scrollbars-custom'

import useStyles from './styles'


const CustomScroll = (props) => {
  const classes = useStyles(props)
  const {
    className,
    children,
    secureYScroll,
  } = props

  // render the scroller
  const renderScrollBar = useMemo(() => (
    <Scrollbar
      className={cx(className, classes.container, secureYScroll && 'is-secure-y-scroll')}
      noDefaultStyles
    >
      {children}
    </Scrollbar>
  ), [children, className, classes.container, secureYScroll])

  return renderScrollBar
}

CustomScroll.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  secureYScroll: PropTypes.bool,
}

CustomScroll.defaultProps = {
  className: null,
  children: null,
  secureYScroll: false,
}

export default CustomScroll
