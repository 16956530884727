import { createUseStyles } from 'react-jss'

// import { colors, desktop, fonts} from '../../theme'
import { colors, desktop, zIndexes } from '../../theme'


export default createUseStyles({
  container: {
    flex: '0 0 auto',
    position: 'relative',
    zIndex: zIndexes.navigation - 1,
    background: colors.pureWhite,
    textAlign: 'center',
    lineHeight: '1.2',
    height: '7rem',
    '&.is-empty-mobile': {
      display: 'none',
    },
  },
  wrapper: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    padding: '1.5rem 0',
    height: '100%',
  },
  list: {
    listStyle: 'none',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    margin: 0,
    padding: 0,
    '&.is-legals': {
      display: 'none',
    },
    '&:first-child': {
      marginLeft: '2rem',
    },
    '& > *': {
      marginRight: '2rem',
    },
  },
  item: {},
  title: {
    display: 'none',
  },
  links: {
    listStyle: 'none',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    '& > *:not(:first-child)': {
      marginLeft: '2rem',
    },
  },
  linkContainer: {},
  link: {
    fontSize: '1.8rem',
    textDecoration: 'underline',
    color: colors.pureBlack,
    whiteSpace: 'nowrap',
    '.is-current &': {
      color: colors.blueLink,
    },
  },
  ...desktop({
    container: {
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      width: '16rem',
      height: 'auto',
      paddingRight: '0.5rem',
      background: colors.pureWhite,
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        top: 0,
        right: 0,
        borderRight: `0.5rem solid ${colors.greyC4}`,
        borderImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyAgMAAABjUWAiAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAMUExURcTExNDQ0Kqqqt7e3ua3UhYAAAAwSURBVCjPY9gaigAPGPDy6v/DwFUgL2sVDEwF8hwYYEB0lDfKI8hDTT2oKYvYFAkAcqOU1dsOwY4AAAAASUVORK5CYII=)',
        borderImageSlice: '10%',
        borderImageWidth: '0 0.5rem 0 0',
      },
      '&.is-empty-mobile': {
        display: 'block',
      },
    },
    wrapper: {
      height: '100%',
      flexFlow: 'column',
      padding: '9rem 1em 2rem',
      '& > *:not(:first-child)': {
        marginLeft: '0',
        marginTop: '2rem',
      },
    },
    list: {
      flexFlow: 'column',
      '&.is-legals': {
        display: 'flex',
      },
      '&.is-legals:first-child': {
        marginTop: 'auto',
      },
      '&:first-child': {
        marginLeft: '0',
      },
      '& > *': {
        marginRight: '0',
      },
      '& > *:not(:first-child)': {
        marginLeft: '0',
        marginTop: '2rem',
      },
      '&.is-bottom': {
        marginTop: 'auto',
      },
      '&.is-legals:not(:first-child)': {
        display: 'flex',
        marginTop: '0',
      },
    },
    item: {
      '& > *:not(:first-child)': {
        marginTop: '0.2rem',
      },
    },
    title: {
      display: 'block',
      textTransform: 'uppercase',
      fontSize: '1.6rem',
      fontWeight: 'normal',
    },
    links: {
      flexFlow: 'column',
      '& > *:not(:first-child)': {
        marginLeft: '0',
        marginTop: '0.2rem',
      },
    },
    link: {
      whiteSpace: 'normal',
      fontSize: '1.6rem',
    },
  }),
})
