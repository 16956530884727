import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'


export const iconsKeys = {
  Logo: 'logo',
  Close: 'close',
  Close2: 'close2',
  Plus: 'plus',
  Check: 'check',
  Play: 'plyr-play',
  Pause: 'plyr-pause',
  Sound: 'plyr-sound',
}

/**
 * @param {Function} Component
 * @param {number} width
 * @param {number} height
 */
const IconWrapper = (fileName, width, height) => {
  const useStyles = createUseStyles()

  const IconAsset = (props) => {
    const classes = useStyles(props)
    const { className } = props

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        className={cx(classes.asset, className)}
      >
        <use xlinkHref={`/icones/spritemap.svg#${fileName}`} />
      </svg>
    )
  }

  IconAsset.useStyles = useStyles
  IconAsset.propTypes = {
    className: PropTypes.string,
  }
  IconAsset.defaultProps = {
    className: '',
  }

  return IconAsset
}

export const icons = {
  [iconsKeys.Logo]: IconWrapper('logo', 217, 25),
  [iconsKeys.Close]: IconWrapper('close', 15, 15),
  [iconsKeys.Close2]: IconWrapper('close2', 16, 16),
  [iconsKeys.Plus]: IconWrapper('plus', 23, 23),
  [iconsKeys.Check]: IconWrapper('check', 24, 24),
  [iconsKeys.Sound]: IconWrapper('plyr-sound', 36, 36),
  [iconsKeys.Play]: IconWrapper('plyr-play', 36, 36),
  [iconsKeys.Pause]: IconWrapper('plyr-pause', 36, 36),
}

export default icons
