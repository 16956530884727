const colors = {
  pureBlack: '#000',
  pureWhite: '#FFF',
  greyD0: '#D0D0D0',
  greyDE: '#DEDEDE',
  greyC4: '#C4C4C4',
  greyAA: '#AAAAAA',
  grey93: '#939393',
  grey3B: '#3B3B3B',
  redError: '#D70000',
  blueLink: '#0000FF',
}

export default colors
