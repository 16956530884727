import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { createUseStyles } from 'react-jss'

import isString from '../../utils/IsString'

import styles from './styles'


const useStyles = createUseStyles(styles)


// /!\ To test gatby-image integration in storybook : query all images in src/images and get one image with img props when is a string (ex: 'article.png')
const Image = (props) => {
  const { img, className, loading, alt } = props
  const classes = useStyles(props)

  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
  `)

  if (isString(img)) {
    const image = img
      ? data.allImageSharp.edges.find((edge) => edge.node.fluid.originalName === img)
      : null

    return image ? (
      <Img
        fluid={image.node.fluid}
        className={cx(classes.image, className, 'is-media__image')}
        loading={loading}
        alt={alt}
        fadeIn={false}
      />
    ) : null
  }
  return (
    <Img
      {...img}
      className={cx(classes.image, className, 'is-media__image')}
      loading={loading}
      alt={alt}
      fadeIn={false}
    />
  )
}

Image.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  img: PropTypes.any.isRequired,
  alt: PropTypes.string,
  loading: PropTypes.oneOf(['lazy', 'eager', 'auto']),
}

Image.defaultProps = {
  className: null,
  alt: '',
  loading: 'lazy',
}

export default Image
