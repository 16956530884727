/* eslint-disable react/prop-types */
/* eslint-disable complexity */
/* eslint-disable no-console */

import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LoginForm, Popin } from '../../components'
import CreateForm from '../../components/CreateForm'
import { selectors as NewsletterSelectors, actions as NewsletterActions } from '../../redux/newsletter/redux'
import { useIntl } from '../../intl/intl'



export default (props) => {
  const dispatch = useDispatch()
  const t = useIntl()
  const [email, setEmail] = useState('')
  const isConnected = useSelector(NewsletterSelectors.connected)
  const hasToComplete = useSelector(NewsletterSelectors.complete)
  //
  const handleLogin = useCallback((event) => {
    event.preventDefault()
    setEmail(event.target.email.value)
    dispatch(NewsletterActions.canAccess({ email: event.target.email.value }))
  }, [setEmail, dispatch])

  const handleSubmit = useCallback((values) => {
    dispatch(NewsletterActions.subscribeNewsletter({
      source: props?.language ?? null,
      email: values?.email ?? '',
      last_name: values?.lastName ?? '',
      name: values?.firstName ?? '',
      civility: values?.civility ?? '',
      optin_fall22: values?.consent ? 1 : 0,
      optin_fall22_2: values?.touConsent ? 1 : 0,
      optin_fall22_3: values?.understand ? 1 : 0,
    }))
  }, [dispatch, props.language])

  const popinLoginProps = useMemo(() => ({
    textButton: t('textButton'),
    textLabelMail: t('textLabelMail'),
  }), [t])

  const popinCreateProps = useMemo(() => ({
    defaultEmail: email,
    formEmailLabel: t('formEmailLabel'),
    formCivilityLabel: t('formCivilityLabel'),
    formCivilityMr: t('formCivilityMr'),
    formCivilityMs: t('formCivilityMs'),
    formCivilityMx: t('formCivilityMx'),
    formCivilityNa: t('formCivilityNa'),
    formFirstnameLabel: t('formFirstnameLabel'),
    formLastnameLabel: t('formLastnameLabel'),
    errorRequiredFields: t('errorRequiredFields'),
    errorEmailFormat: t('errorEmailFormat'),
    formSuccessMessage: t('formSuccessMessage'),
    signUpErrorMessage: t('signUpErrorMessage'),
    newsletterOptinText: t('newsletterOptinText'),
    acceptTOUText: t('acceptTOUText'),
    formSignupLabel: t('formSignupLabel'),
    understandOptinText: t('understandOptinText'),
  }), [email, t])
  //

  return (
    <>
      <Popin isOpen={!isConnected && !hasToComplete}>
        <LoginForm
          {...popinLoginProps}
          submitHandler={(e) => handleLogin(e)}
        />
      </Popin>
      {hasToComplete && (
      <Popin isOpen={!isConnected && hasToComplete}>
        <CreateForm
          {...popinCreateProps}
          submitHandler={(values) => handleSubmit(values)}
        />
      </Popin>
      )}

    </>
  )
}
