import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
// import { Link as Gatsbylink } from 'gatsby'
import { Link as IntlLink } from 'gatsby-plugin-intl'
import ClassNames from 'classnames'

import router from '../../router'

import useStyles from './styles'


const Link = ({
  children,
  link,
  target,
  title,
  text,
  className,
  role,
  tabIndex,
  onClick,
  route,
  routeParams,
  rel,
  download,
}) => {
  const classes = useStyles()

  const renderDefault = () => (
    <a
      className={ClassNames(className, classes.container)}
      onClick={onClick}
      role={role}
      tabIndex={tabIndex}
      title={title}
      aria-label={title}
      href="javascript:void(0)"
      download={download}
    >
      {children ?? text}
    </a>
  )

  const renderAsLink = () => {
    if (link) {
      return (
        <a
          className={ClassNames(className, classes.container)}
          title={title}
          href={link}
          aria-label={title}
          target={target}
          onClick={onClick}
          rel={rel}
          role={role}
          tabIndex={tabIndex}
          download={download}
        >
          {children ?? text}
        </a>
      )
    }

    return (
      <IntlLink
        to={router(route, routeParams)}
        className={ClassNames(className, classes.container)}
        title={title}
        aria-label={title}
        onClick={onClick}
        role={role}
        tabIndex={tabIndex}
        target={target}
        download={download}
      >
        {children ?? text}
      </IntlLink>
    )
  }

  return <>{link || route ? renderAsLink() : renderDefault()}</>
}

Link.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  link: PropTypes.string,
  target: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  role: PropTypes.string,
  tabIndex: PropTypes.number,
  route: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  routeParams: PropTypes.object,
  rel: PropTypes.string,
  download: PropTypes.string,
}

Link.defaultProps = {
  className: null,
  children: null,
  onClick: () => undefined,
  link: undefined,
  target: undefined,
  title: null,
  text: null,
  role: undefined,
  tabIndex: undefined,
  route: null,
  routeParams: {},
  rel: undefined,
  download: undefined,
}

export default Link
