import { createUseStyles } from 'react-jss'

import { colors, desktop } from '../../theme'



export default createUseStyles({
  container: {
    position: 'relative',
  },
  video: {
    display: 'flex',
    width: '100%',
    background: colors.grey3B,
    // video styles for IE11, because.....
    '_:-ms-fullscreen, :root &': {
      height: '35.1rem',
    },
    '& > *': {
      width: '100%',
    },
    // plyr
    '& .plyr': {
      '--plyr-color-main': '#000',
      '--plyr-video-control-color': '#000',
      '--plyr-video-background': '#000',
      '--plyr-control-radius': '0',
      '--plyr-range-thumb-background': 'linear-gradient(180deg, #FFFFFF 6.25%, #CACACA 34.9%, #B7B7B7 52.6%, #E3E1E1 68.75%, #FFFFFF 85.42%)',
      '--plyr-control-padding': '0',
      '--plyr-control-spacing': '0',
      '--plyr-range-thumb-height': '1.2rem',
      '--plyr-range-track-height': '0.5rem',
      '--plyr-range-thumb-shadow': '0 none',
      '--plyr-progress-loading-background': 'transparent',
      '--plyr-video-progress-buffered-background': 'transparent',
      '--plyr-video-range-track-background': '#fff',
      '--plyr-range-fill-background': 'rgba(0, 0, 0, 0.05)',
      '--plyr-control-icon-size': '1rem',
      '--plyr-video-control-color-hover': '#000',
      '--plyr-video-control-background-hover': 'transparent',
      '--plyr-tooltip-padding': '0.5rem !important',
    },
    '& .plyr__controls': {
      padding: 0,
      background: 'linear-gradient(180deg, #D3D3D3 0%, #E3E3E3 32.81%, #D3D3D3 51.04%, #FFFFFF 84.37%)',
    },
    '& .plyr__controls::before, & .plyr__controls::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      height: '1px',
    },
    '& .plyr__controls::before': {
      top: 0,
      background: '#BCBDBF',
    },
    '& .plyr__controls::after': {
      bottom: 0,
      background: '#D1D1D1',
    },
    '& .plyr__control': {
      padding: '0 0.4rem',
      height: '1.7rem',
      borderRight: '1px solid #A7A7A7',
    },
    '& .plyr__volume .plyr__control': {
      borderLeft: '1px solid #A7A7A7',
    },
    '& .plyr__controls .plyr__controls__item.plyr__progress__container': {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
    // remove if the volume input control comes back
    '& .plyr__volume': {
      width: '2rem',
      minWidth: '2rem',
      maxWidth: '2rem',
    },
    '& .plyr__volume input': {
      marginLeft: '0.5rem',
      marginRight: '0.5rem',
    },
    '& input[type=range]::-webkit-slider-thumb, & input[type=range]:focus::-webkit-slider-thumb': {
      boxShadow: '0 0 2px rgba(0, 0, 0, 0.5), 0 0 1px rgba(0, 0, 0, 0.2)',
    },
    '& input[type=range]::-moz-range-thumb': {
      boxShadow: '0 0 2px rgba(0, 0, 0, 0.5), 0 0 1px rgba(0, 0, 0, 0.2)',
    },
    '& input[type=range]::-ms-thumb': {
      boxShadow: '0 0 2px rgba(0, 0, 0, 0.5), 0 0 1px rgba(0, 0, 0, 0.2)',
    },
    '& input[type=range]::-webkit-slider-runnable-track': {
      background: 'linear-gradient(180deg, #5B5B5B 0%, #CBCBCB 53.12%) !important',
    },
    '& input[type=range]::-moz-range-track': {
      background: 'linear-gradient(180deg, #5B5B5B 0%, #CBCBCB 53.12%) !important',
    },
    '& input[type=range]::-ms-track': {
      background: 'linear-gradient(180deg, #5B5B5B 0%, #CBCBCB 53.12%) !important',
    },
    '& .plyr__tooltip': {
      padding: '0.5rem',
    },
  },
  title: {
    paddingLeft: '0.4rem',
    paddingRight: '0.4rem',
    margin: '0.4rem 0 0',
    fontWeight: 'normal',
    fontSize: '1.3rem',
    lineHeight: 1.2,
  },
  text: {
    paddingLeft: '0.4rem',
    paddingRight: '0.4rem',
    margin: '1.5rem 0 1rem',
    fontWeight: 'normal',
    fontSize: '1.3rem',
    lineHeight: 1.2,
  },
  ...desktop({
    title: {
      fontSize: '1.4rem',
    },
  }),
})
