import { createUseStyles } from 'react-jss'

import { colors, desktop } from '../../theme'


export default createUseStyles({
  container: {
    maxWidth: '22rem',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    textAlign: 'center',
  },
  form: {
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
  },
  label: {
    fontSize: '1.8rem',
    textTransform: 'uppercase',
  },
  inputs: {
    display: 'flex',
    justifyContent: 'center',
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
    '& > *:not(:first-child)': {
      marginLeft: '1rem',
    },
    '& input': {
      flex: '0 0 auto',
      width: '2.3rem',
      height: '2.8rem',
      textAlign: 'center',
    },
  },
  errors: {
    marginTop: '1rem',
    textAlign: 'center',
    fontSize: '1.2rem',
    color: colors.redError,
  },
  actions: {
    marginTop: '3rem',
  },
  action: {
    width: '11rem',
  },
  ...desktop({
    input: {
      '& input': {
        height: '2.5rem',
        fontSize: '1.6rem',
      },
    },
  }),
})
