/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import useStyles from './styles'
import Video from './blue_screen_glitched_1920x1080.mp4'


const Glitch = (props) => {
  const classes = useStyles(props)
  const {
    className,
    children,
  } = props


  return (
    <div className={cx(className, classes.container)}>
      <div className={classes.video}>
        <video
          src={Video}
          type="video/mp4"
          autoPlay
          muted
          loop
          playsInline
        />
      </div>
      {children}
    </div>
  )
}

Glitch.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

Glitch.defaultProps = {
  className: null,
  children: null,
}

export default Glitch
