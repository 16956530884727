

const LinkTransformer = (data, slug, template) => ({
  text: data?.title ?? '',
  link: data?.link ?? '',
  target: data?.target ?? '_self',
  ...(data?.handleClickDownload ? { onClick: (e) => data.handleClickDownload(e) } : {}),
  isCurrent: data?.link === '/' ? template === 'Homepage' : data?.link.indexOf(slug) >= 0,

})

const MenuItemTransformer = (data, configuration, slug, template) => ({
  title: data?.title ?? '',
  links: data?.menuCollection?.items?.filter((item) => (
    (item.context === 'Pres-Show-Only' && configuration.isBeforeShow)
      || (item.context === 'After-Show-Only' && configuration.isAfterShow)
      || item.context === 'Both'
  ))?.map((item) => LinkTransformer(item, slug)),
})
const headerTransformer = (configuration, t, slug, template) => {
  {
    const result = {
      titleTop: t('titleTop'),
      titleBottom: t('titleBottom'),
      textBrand: t('textBrand'),
      textTape: t('textTape'),
    }

    if (configuration.isBeforeShow) {
      result.textDate = t('textDate')
    }

    if (template !== 'Homepage') {
      result.linkProps = {
        link: '/',
      }
    }

    return result
  }
}

const navTransformer = (configuration, t, handleClickDownload, slug, template) => {
  const linksTop = []
  const linksBottom = []
  const linksLegal = []

  configuration?.configuration?.menuTopCollection?.items?.forEach((item) => {
    if ((item.context === 'Pres-Show-Only' && configuration.isBeforeShow)
        || (item.context === 'After-Show-Only' && configuration.isAfterShow)
        || item.context === 'Both'
    ) {
      linksTop.push(MenuItemTransformer(item, configuration, slug, template))
    }
  })

  configuration?.configuration?.menuBottomCollection?.items?.forEach((item) => {
    if ((item.context === 'Pres-Show-Only' && configuration.isBeforeShow)
        || (item.context === 'After-Show-Only' && configuration.isAfterShow)
        || item.context === 'Both'
    ) {
      linksBottom.push(LinkTransformer(item, slug, template))
    }
  })

  if (configuration.isAfterShow) {
    linksTop.push(MenuItemTransformer({
      title: t('presskit'),
      menuCollection: {
        items: [{
          context: 'After-Show-Only',
          title: t('download'),
          link: '#',
          handleClickDownload,
        }],
      },

    }, configuration, slug, template))
  }

  configuration?.configuration?.menuLegalCollection?.items?.forEach((item) => {
    if ((item.context === 'Pres-Show-Only' && configuration.isBeforeShow)
        || (item.context === 'After-Show-Only' && configuration.isAfterShow)
        || item.context === 'Both'
    ) {
      linksLegal.push(LinkTransformer(item, slug, template))
    }
  })

  return {
    linksTop,
    linksBottom: [...[{ links: linksBottom }]],
    linksLegal: [...[{ links: linksLegal }]],
  }
}

export default (configuration, t, handleClickDownload, slug, template) => ({
  headerProps: headerTransformer(configuration, t, slug, template),
  navProps: navTransformer(configuration, t, handleClickDownload, slug, template),
})


