import React, { useCallback } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { iconsKeys } from '../Icon/Icon.assets'
import { Icon } from '..'

import useStyles from './styles'


const Popin = (props) => {
  const classes = useStyles(props)
  const {
    className,
    isOpen,
    isClosable,
    children,
    closeHandler,
  } = props

  const handleClose = useCallback(() => {
    if (closeHandler) {
      closeHandler()
    }
  }, [closeHandler])

  return (
    <div className={cx(className, classes.container, isOpen && 'is-open')}>
      <div
        className={classes.wrapper}
      >
        {children}
        {isClosable === true && (
          <button
            type="button"
            className={classes.close}
          >
            <Icon
              icon={iconsKeys.Close}
              onClick={handleClose}
            />
          </button>
        )}
      </div>
      <div
        className={classes.overlay}
      />
    </div>
  )
}

Popin.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  isClosable: PropTypes.bool,
  children: PropTypes.node,
  closeHandler: PropTypes.func,
}

Popin.defaultProps = {
  className: null,
  isOpen: false,
  isClosable: false,
  children: null,
  closeHandler: () => null,
}

export default Popin
