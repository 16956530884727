import {
  desktopMediaQuery,
  fullDesktopMediaQuery,
  maxDesktopMediaQuery,
  notDesktopMediaQuery,
  notFullDesktopMediaQuery,
  notMaxDesktopMediaQuery,
  notTabletMediaQuery,
  tabletMediaQuery,
  hdmiMediaQuery,
  notHdmiMediaQuery
} from './sizes'

/*
 * Mixins
 */

/**
 * @typedef {Object.<string, any>} CSSDefinition
 * @typedef {Object.<string, CSSDefinition>} CSSMedia
 */

/**
 * @param {string | string[] | null} trans
 * @param {string | string[] | null} duration
 * @param {string | string[] | null} easing
 * @returns {{transition: string}}
 */
export const transition = (trans = 'all', duration = '200ms', easing = 'ease') => ({
  transition: Array.isArray(trans)
    ? trans
      .map((transIt, transIndex) => {
        const transDuration = Array.isArray(duration)
          ? duration[transIndex] ?? duration[0]
          : duration
        const transEasing = Array.isArray(easing) ? easing[transIndex] ?? easing[0] : easing

        return `${transIt} ${transDuration} ${transEasing}`
      })
      .join(', ')
    : `${trans} ${duration} ${easing}`,
})

/**
 * @param {string} hex
 * @param {number} opacity
 * @returns {string}
 */
export const alpha = (hex, opacity = 1) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (opacity) {
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  }
  return `rgb(${r}, ${g}, ${b})`
}

/**
 * @param {string} css
 * @returns {string}
 */
export const important = (css) => `${css} !important`

/**
 * @param {string} color
 * @param {{duration: string, effect: string} | null} transitionProperties
 */
export const svgGlobal = (color, transitionProperties = null) => ({
  '& *[fill]:not([fill="none"]):not(.no-fill)': {
    fill: color,
    extend: transitionProperties
      ? transition('fill', transitionProperties.duration, transitionProperties.effect)
      : null,
  },
  '& *[stroke]:not([stroke="none"]):not(.no-stroke)': {
    stroke: color,
    extend: transitionProperties
      ? transition('stroke', transitionProperties.duration, transitionProperties.effect)
      : null,
  },
  '& path': {
    extend: transitionProperties
      ? transition('fill', transitionProperties.duration, transitionProperties.effect)
      : null,
  },
})

export const svgColor = svgGlobal

/**
 * @param {CSSDefinition} css
 */
export const svgForce = (css) => ({
  '& > *, & > * > *': {
    ...css,
  },
})

/**
 * @param {string} color
 * @param {{}} font
 */
export const placeholder = (color, font = {}) => {
  /**
   * @param {string} c
   * @param {{}} f
   */
  const getPlaceHolder = (c, f) => ({
    '& [placeholder], &::placeholder': {
      color: c,
      ...f,
    },
    '&::-webkit-input-placeholder': {
      color: c,
      ...f,
    },
    '&:-moz-placeholder': {
      color: c,
      ...f,
    },
    '&::-moz-placeholder': {
      color: c,
      ...f,
    },
    '&:-ms-input-placeholder': {
      color: c,
      ...f,
    },
    '&::-ms-input-placeholder': {
      color: c,
      ...f,
    },
  })

  if (typeof color === 'function') {
    return {
      ...getPlaceHolder(color, font),
    }
  }

  return getPlaceHolder(color, font)
}

/**
 * @param {number} width
 * @param {number} height
 */
export const aspectRatio = (width, height) => ({
  width: '100%',
  paddingTop: `${(height / width) * 100}%`,
  position: 'relative',
  height: 0,
  '& > *': {
    position: 'absolute !important',
    top: '0 !important',
    left: '0 !important',
    width: '100% !important',
    height: '100% !important',
  },
})

/*
 * This project use mobile first integration
 * Default media is mobile
 */

/**
 * @param {CSSDefinition} content
 * @returns {CSSMedia}
 */
export const tablet = (content) => ({
  [tabletMediaQuery]: {
    ...content,
  },
})

/**
 * Use in extreme necessity, prefer tablet
 * @param {CSSDefinition} content
 * @returns {CSSMedia}
 */
export const notTablet = (content) => ({
  [notTabletMediaQuery]: {
    ...content,
  },
})

/**
 * @param {CSSDefinition} content
 * @returns {CSSMedia}
 */
export const desktop = (content) => ({
  [desktopMediaQuery]: {
    ...content,
  },
})

/**
 * Use in extreme necessity, prefer desktop
 * @param {CSSDefinition} content
 * @returns {CSSMedia}
 */
export const notDesktop = (content) => ({
  [notDesktopMediaQuery]: {
    ...content,
  },
})

/**
 * @param {CSSDefinition} content
 * @returns {CSSMedia}
 */
export const fullDesktop = (content) => ({
  [fullDesktopMediaQuery]: {
    ...content,
  },
})

/**
 * Use in extreme necessity, prefer fullDesktop
 * @param {CSSDefinition} content
 * @returns {CSSMedia}
 */
export const notFullDesktop = (content) => ({
  [notFullDesktopMediaQuery]: {
    ...content,
  },
})

/**
 * @param {CSSDefinition} content
 * @returns {CSSMedia}
 */
export const maxDesktop = (content) => ({
  [maxDesktopMediaQuery]: {
    ...content,
  },
})

/**
 * Use in extreme necessity, prefer maxDesktop
 * @param {CSSDefinition} content
 * @returns {CSSMedia}
 */
export const notMaxDesktop = (content) => ({
  [notMaxDesktopMediaQuery]: {
    ...content,
  },
})

/**
 * @param {CSSDefinition} content
 * @returns {CSSMedia}
 */
export const hdmi = (content) => ({
  [hdmiMediaQuery]: {
    ...content,
  },
})

/**
 * @param {CSSDefinition} content
 * @returns {CSSMedia}
 */
export const notHdmi = (content) => ({
  [notHdmiMediaQuery]: {
    ...content,
  },
})
