import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import Link from '../Link'

import useStyles from './styles'


// eslint-disable-next-line complexity
const Header = (props) => {
  const classes = useStyles(props)
  const {
    className,
    titleTop,
    titleBottom,
    textBrand,
    textTape,
    textDate,
    linkProps,
  } = props

  const renderTitle = useMemo(() => ((titleTop || titleBottom) ? (
    <>
      {titleTop && (<span>{titleTop}</span>)}
      {titleBottom && (<span>{titleBottom}</span>)}
    </>
  ) : null), [titleTop, titleBottom])

  const renderTitleWithLink = useMemo(() => (
    (renderTitle && linkProps) ? (
      <h1 className={classes.title}>
        <Link {...linkProps}>
          {renderTitle}
        </Link>
      </h1>
    ) : (renderTitle) && (
      <h1 className={classes.title}>
          {renderTitle}
      </h1>
    )), [classes, linkProps, renderTitle])

  return (
    <header className={cx(className, classes.container)}>
      <div className={classes.wrapper}>
        {renderTitleWithLink}
        <div className={cx(classes.content, textDate && 'is-long')}>
          {(textBrand || textTape) && (
            <p>
              {textBrand && (
                <span>
                  {textBrand}
                  {textTape && ' / '}
                </span>
              )}
              {textTape && (
                <span>{textTape}</span>
              )}
            </p>
          )}
          {textDate && (
            <p>{textDate}</p>
          )}
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  titleTop: PropTypes.string,
  titleBottom: PropTypes.string,
  textBrand: PropTypes.string,
  textTape: PropTypes.string,
  textDate: PropTypes.string,
  linkProps: PropTypes.shape(Link.propTypes),
}

Header.defaultProps = {
  className: null,
  titleTop: null,
  titleBottom: null,
  textBrand: null,
  textTape: null,
  textDate: null,
  linkProps: null,
}

export default Header
