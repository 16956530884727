import { createUseStyles } from 'react-jss'

import { colors, desktop, zIndexes } from '../../theme'


export default createUseStyles({
  container: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: zIndexes.modal,
    opacity: 0,
    '&.is-open': {
      opacity: 1,
    },
    '&, & *': {
      pointerEvents: 'none',
    },
    '&.is-open, &.is-open *': {
      pointerEvents: 'all',
    },
  },
  wrapper: {
    position: 'absolute',
    top: '8rem',
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: zIndexes.default + 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2.5rem',
    overflow: 'auto',
    border: `0.5rem solid ${colors.greyAA}`,
    borderImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyAgMAAABjUWAiAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAMUExURcTExNDQ0Kqqqt7e3ua3UhYAAAAwSURBVCjPY9gaigAPGPDy6v/DwFUgL2sVDEwF8hwYYEB0lDfKI8hDTT2oKYvYFAkAcqOU1dsOwY4AAAAASUVORK5CYII=)',
    borderImageSlice: '10%',
    borderImageWidth: '0.5rem',
    background: colors.greyC4,
    '.is-page__with-nav & ': {
      top: '15.5rem',
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: zIndexes.default,
    background: 'transparent',
  },
  close: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    background: 'transparent',
    outline: '0 none',
  },
  ...desktop({
    wrapper: {
      top: '50%',
      left: '40rem',
      height: 'max-content',
      width: 'max-content',
      transform: 'translate(-50%, -50%)',
      '.is-page__with-nav & ': {
        top: '50%',
      },
    },
  }),
})
