import React, { useCallback } from 'react'

import * as components from '..'
import * as transformers from '../transformers'
import { useIntl } from '../../intl/intl'


const Blocks = ({ blocks, extraParams = {} }) => {
  const t = useIntl()
  const renderBlock = useCallback(
    (block, key) => {
      const Block = components[block.__typename] ?? null

      if (!Block) {
        console.warn(`Missing block : ${block.__typename}`)
        return null
      }
      const datas = transformers[block.__typename]
        ? transformers[block.__typename](block, t)
        : block
      // console.log('Datas block : ' + block.__typename, datas)

      return (
        <Block
          {...datas}
          {...extraParams}
          key={`${block.__typename}-${key}`}
        />
      )
    },
    [extraParams, t]
  )

  return <>{blocks?.map(renderBlock)}</>
}

export default Blocks
