import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { withTheme } from 'theming'

import useStyles from './styles'


const Button = (props) => {
  const classes = useStyles(props)

  const { className, children } = props

  return (
    <span
      className={cx(classes.container, className)}
    >
      {children}
    </span>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

Button.defaultProps = {
  className: null,
  children: null,
}

export default withTheme(Button)
