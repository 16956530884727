import PropTypes from 'prop-types'

import useStyles from './styles'


export default function AppWithStyle(props) {
  const { children } = props

  useStyles()

  return children
}

AppWithStyle.propTypes = {
  children: PropTypes.node.isRequired,
}
