import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const AccessibleHiddenText = (props) => {
  const classes = useStyles(props)
  const { className, text } = props

  if (!text) {
    return null
  }

  return <span className={cx(classes.container, className)}>{text}</span>
}

AccessibleHiddenText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
}

AccessibleHiddenText.defaultProps = {
  className: null,
  text: '',
}

export default withMemo()(AccessibleHiddenText)
