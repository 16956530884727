import { useMemo } from 'react'

import { isDateInThePast } from '../utils/DateUtils'


const useConfiguration = (configuration) => {
  const seoFallback = useMemo(() => configuration?.seo, [configuration])

  return {
    configuration: { ...configuration } ?? {},
    isBeforeShow: configuration?.countdown ? !isDateInThePast(configuration?.countdown, new Date()) : true,
    isAfterShow: configuration?.countdown ? isDateInThePast(configuration?.countdown, new Date()) : false,
    seoFallback,
  }
}

export default useConfiguration
