import '../../utils/polyfills'
import '../../utils/proxy'

import React, { Fragment, PureComponent } from 'react'
import { ThemeProvider } from 'react-jss'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

import * as theme from '../../theme'
import { AppWithStyle } from '..'
import Seo from '../Seo'
import DefaultHead from '../DefaultHead'


export default class App extends PureComponent {

  // eslint-disable-next-line
  static propTypes = {
    // eslint-disable-next-line
    children: PropTypes.node.isRequired,
    // eslint-disable-next-line
    seo: PropTypes.shape(Seo.propTypes).isRequired,
  }

  render() {
    const { children, seo } = this.props

    return (
      <>
        <Helmet>
          <link
            href="https://fr.allfont.net/allfont.css?fonts=arial-narrow"
            rel="stylesheet"
            type="text/css"
          />
        </Helmet>
        <ThemeProvider theme={theme}>
          <AppWithStyle>
            <DefaultHead />
            <Seo {...seo} />
            {children}
          </AppWithStyle>
        </ThemeProvider>
      </>
    )
  }

}
